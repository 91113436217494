@font-face {
  font-family: 'Usual';
  src: url('./Usual-Regular.eot?#iefix') format('embedded-opentype'),
    url('Usual-Regular.ttf') format('truetype'),
    url('Usual-Regular.woff') format('woff'),
    url('Usual-Regular.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Usual';
  src: url('./Usual-Bold.eot?#iefix') format('embedded-opentype'),
    url('Usual-Bold.ttf') format('truetype'),
    url('Usual-Bold.woff') format('woff'),
    url('Usual-Bold.woff2') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LemonYellowSun';
  src: url('./LemonYellowSunExtraBold-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./LemonYellowSunExtraBold-Regular.otf') format('opentype'),
    url('./LemonYellowSunExtraBold-Regular.woff') format('woff'),
    url('./LemonYellowSunExtraBold-Regular.ttf') format('truetype'),
    url('./LemonYellowSunExtraBold-Regular.svg#LemonYellowSunExtraBold-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
